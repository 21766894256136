@charset "utf-8";

.menu {
  position: fixed;
  top: 12px;
  right: 15px;
  background: $opa-w2;
  padding: 7px;
  z-index: 5000;
}
#touch, .menu span {
  display: inline-block;
  -webkit-transition: all .4s;
  transition: all .4s;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
#touch {
  position: relative;
  width: 25px;
  height: 21px;
  span {
    position: absolute;
    left: 0;
    width: 100%;
    height: 3px;
    background: #666;
    &:nth-of-type(1) {
      top: 0;
    }
    &:nth-of-type(2) {
      top: 9px;
    }
    &:nth-of-type(3) {
      bottom: 0;
    }
   }
}
nav {
  display: none;
  position: absolute;
  top: 60px;
  left: 0;
  padding: 10px 20px 30px;
  width: 100%;
  height: 100%;
  background: $opa-ol;
  z-index: 1000;
  ul {
    letter-spacing: .05em;
  }
  li {
    font-family: 'Noto Sans JP', sans-serif;
    font-weight: 500;
    font-size: $f16;
    border-bottom: 1px rgba(255,255,255,.5) solid;
    a {
      position: relative;
      display: block;
      line-height: 50px;
      &:after {
        position: absolute;
        top: 0;
        right: 5px;
        font-family: 'ionicons';
        font-size: $f24;
        content: '\f3d0';
        color: rgba(255,255,255,.6)
      }
      &.active:after {
        content: '\f3d8';
      }
      &.rightArrow:after {
        content: '';
      }
    }
    > ul {
      display: none;
      margin-bottom: 20px;
      padding: 0 1em;
      background: rgba(255,255,255,0.2);
      border-radius: 5px;
      > li {
        font-weight: 300;
        font-size: $f14;
        border-bottom: 1px rgba(255,255,255,.6) dotted;
        &:last-child {
          border-bottom: none;
        }
        a {
          line-height: 36px;
          &:after {
            content: '';
          }
          &#sub2menu01,
          &#sub2menu02 {
            &:after {
              right: 8px;
              font-size: $f20;
              content: '\f3d0';
              color: white;
            }
            &.active:after {
              content: '\f3d8';
            }
          }
        }
        > ul {
          margin: 0 -1em;
          padding: 0 0 0 2em;
          border-radius: 0;
          >li {
            border-bottom: none;
          }
        }
      }
    }
  }
  a {
    color: white;
  }
  form {
    position: relative;
    input[type="text"] {
      padding-left: 10px;
      width: 100%;
      font-size: $f12;
      line-height: 3;
      color: white;
      border: 1px rgba(255,255,255,.6) solid;
      background: transparent;
      box-sizing: border-box;
      &::placeholder {
        color: rgba(255,255,255,.6);
      }
    }
    button{
        background-color: transparent;
        border: none;
        cursor: pointer;
        outline: none;
        padding: 0;
        appearance: none;
}
  }
}
.asidemenu {
  display: flex;
  margin: 30px 0;
  p {
    width: 50%;
    font-size: $f12;
    text-align: center;
    &:first-child {
      border-right: 1px white solid;
    }
    a {
      display: block;
    }
  }
}

/* Tablet */
@media screen and (min-width:768px) {

  .menu {
    display: none;
  }
  nav {
    display: block;
    position: relative;
    top: 0;
    padding: 0 40px;
    height: 50px;
    background: white;
    ul {
      display: flex;
      letter-spacing: 0;
    }
    li {
      width: 20%;
      font-size: $f13;
      border-left: 1px #ccc solid;
      border-bottom: none;
      &:first-child {
        border-left: none;
      }
      a {
        position: static;
        color: #666;
        text-align: center;
        &:hover {
          background: $color-ol;
          color: white;
        }
        &::after {
          display: none;
        }
        &.active {
          color: white;
          background: $color-ol;
          &::after {
            display: none;
          }
        }
      }
      > ul {
        position: absolute;
        top: 50px;
        left: 0;
        margin: 0;
        padding: 20px 40px;
        width: 100%;
        background: $opa-ol;
        border-radius: 0;
        overflow: hidden;
        > li {
          float: left;
          margin: 5px 1.5%;
          width: 22%;
          font-size: $f12;
          border: none;
          a {
            color: white;
            background: darken($color-ol,10%);
            border-radius: 5px;
            &:hover {
              background: darken($color-ol,30%);
            }
          }
          > ul {
            //display: block;
          }
        }
      }
    }
  }
  .asidemenu,form {
    display: none;
  }

}

/* PC */
@media screen and (min-width:1040px) {

  nav {
    padding: 0;
    ul {
      margin: 0 auto;
      width: 1040px;
    }
    li {
      font-size: $f16;
      > ul {
        left: 50%;
        margin-left: -520px;
        padding: 40px;
        width: 1040px;
        > li {
          float: left;
          margin: 10px 1.5%;
          width: 22%;
          font-size: $f14;
          a {
            padding: 20px 0;
            line-height: 1;
          }
        }
      }
    }
  }

}
