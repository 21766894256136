@charset "utf-8";
@import "reset";
@import "setting";
@import "mixin";

// 各ページ共通

// グローバル
html {
  font-size: 14px;
}
body {
  font-family: $font-body;
  color: $color-black;
  background: #fff;
}
a {
  text-decoration: none;
  -webkit-transition: 0.3s ease-out;
  transition: 0.3s ease-out;
}
img {
  max-width: 100%;
  vertical-align: bottom;
}
small {
  font-size: $f12;
}

// ヘッダ
header {
  padding: 0 20px;
}
.headerInner {
  position: relative;
  height: 60px;
  display: flex;
  align-items: center;
  h1 {
    width: 250px;
    a {
      display: block;
      padding-top: 14%;
      height: 0;
      background: url(../common/logo@2x.png) left center no-repeat;
      background-size: contain;
      @include textindent;
      &:hover {
        opacity: .6;
      }
    }
  }
  ul,form {
    display: none;
  }
}


// メイン
section {
  padding: 35px 20px 30px;
  p {
    line-height: 1.7;
  }
  &.first {
    padding-top: 50px;
    background: $color-gray;
  }
}

// フッタ
footer {
  padding: 0;
  background: #666;
  p {
    padding: 20px;
    font-family: 'Roboto', sans-serif;
    font-size: $f11;
    font-weight: 300;
    text-align: center;
    color: #aaa;
    background: white;
  }
}
.footerInner {
  display: none;
}
.subNav {
  padding: 10px 20px;
  background: #333;
  text-align: center;
  border-top: 1px $color-ol solid;
  li {
    display: inline-block;
    margin: 4px 0;
    padding: 0 .7em;
    font-family: 'Noto Sans JP', sans-serif;
    font-size: $f12;
    font-weight: 300;
    line-height: 1.2;
    border-right: 1px rgba(255,255,255,.5) solid;
    &:last-child {
      border-right: none;
    }
    a {
      color: white;
      &:hover {
        color: $color-ol;
      }
    }
  }
}


.break-s {
  display: inline-block;
}
#pagetop {
  position: fixed;
  right: 20px;
  bottom: 20px;
  a {
    display: block;
    padding-top: 3px;
    width: 50px;
    height: 50px;
    color: #fff;
    background: rgba($color-black,0.7);
    border-radius: 50%;
    &:before {
      margin-left: 12.5px;
      font-family: 'Ionicons';
      content: '\f3d8';
      font-size: 40px;
      text-align: center;
    }
    &:hover {
      background: $opa-ol;
    }
  }
}

/* iPad */
@media screen and (min-width:768px) {

  header {
    padding: 10px 25px 0;
  }
  .headerInner {
    //height: 60px;
    justify-content: space-between;
    align-items: flex-start;
    h1 {
      //width: 300px;
      //margin: 0;
    }
    .topSub {
      display: flex;
      align-items: center;
    }
    ul {
      display: block;
      font-size: $f12;
    }
    li {
      display: inline;
      padding-left: 1.5em;
      a {
        color: #666;
        &:hover {
          color: $color-ol;
        }
      }
      &::before {
        margin-right: 5px;
        font-family: 'Ionicons';
        content: '\f363';
        color: $color-ol;
      }
    }
    form {
      display: block;
      position: relative;
      margin-left: 15px;
      input[type="text"] {
        padding-left: 10px;
        width: 15em;
        font-size: $f12;
        line-height: 2;
        //color: white;
        border: 1px #ccc solid;
        box-sizing: border-box;
        &::placeholder {
          color: #ccc;
        }
      }
      button{
        position: absolute;
        top: 5px;
        right: 10px;
        background-color: transparent;
        border: none;
        cursor: pointer;
        outline: none;
        padding: 0;
        appearance: none;
        img {
          width: 16px;
        }
      }
    }
  }
  section {
    padding: 50px 40px;
    &.first {
      padding: 60px 40px 40px;
    }
  }
  .footerInner {
    display: block;
    padding: 25px 40px 20px;
    font-family: 'Noto Sans JP', sans-serif;
    font-size: $f13;
    font-weight: 500;
    a {
      color: white;
    }
  }
  .footerNav {
    margin-top: 20px;
    padding-top: 20px;
    display: flex;
    //flex-wrap: wrap;
    justify-content: space-between;
    border-top: $w-border;
    > li {
      //width: 20%;
      > ul {
        margin-top: 20px;
        font-size: $f12;
        font-weight: 300;
        > li {
          margin-bottom: 8px;
          line-height: 1.3;
          a {
            color: $opa-w2;
            &:hover {
              color: $color-ol;
            }
          }
          > ul {
            margin: 5px 0 8px .5em;
            li {
              margin-bottom: 5px;
              &::before {
                margin-right: 5px;
                font-family: 'Ionicons';
                content: '\f363';
                color: $opa-w2;
              }
            }
          }
        }
      }
    }

  }
  .subNav {
    padding: 10px 40px;
    li {
      padding: 0 1em;
    }
  }

}

/* PC */
@media screen and (min-width:1040px) {

  header {
    margin: 0 auto;
    width: 1040px;
  }
  .headerInner {
    height: 70px;
    h1 {
      width: 300px;
    }
  }
  footer {
    p {
      padding: 30px;
      font-size: $f12;
    }
  }
  .footerInner {
    margin: 0 auto;
    padding: 25px 20px 20px;
    width: 1040px;
    font-size: $f16;
  }
  .footerNav {
    margin-top: 20px;
    padding-top: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    border-top: $w-border;
    > li {
      width: 20%;
      > ul {
        //margin-top: 20px;
        font-size: $f14;
        > li {
          margin-bottom: 10px;
          //line-height: 1.3;
          > ul {
            margin-bottom: 15px;
            li {
              //margin-bottom: 5px;
            }
          }
        }
      }
    }
  }
  .subNav {
    padding: 10px 20px;
    li {
      padding: 0 2em;
    }
  }

}
