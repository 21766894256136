// 設定用
@import url('https://fonts.googleapis.com/css?family=Noto+Sans+JP:100,300,400,500,700&display=swap');

@import url('https://fonts.googleapis.com/css?family=M+PLUS+1p:100,300,500,700&display=swap');

@import url('https://fonts.googleapis.com/css?family=Roboto:300,500,700&display=swap');

// フォント
$font-body: -apple-system, BlinkMacSystemFont, "ヒラギノ角ゴ ProN W3", Hiragino Kaku Gothic ProN, Arial, Meiryo, sans-serif;

// カラー
$color-red: #ff291b;
$color-blue: #3651a7;
$color-black: #474747;
$color-gray: #f1f1f1;
$color-ol: #f39800;

// フォントサイズ
$f11: .78571429rem;
$f12: .85714286rem;
$f13: .92857143rem;
$f14: 1rem;
$f16: 1.14285714rem;
$f18: 1.28571429rem;
$f20: 1.42857143rem;
$f24: 1.71428571rem;
$f28: 2rem;
$f35: 2.5rem;
$f42: 3rem;
$f56: 4rem;

// 透過色
$opa-w1: rgba(255,255,255,.3);
$opa-w2: rgba(255,255,255,.7);
$opa-black: rgba($color-black,.9);
$opa-gray: rgba($color-black,.05);
$opa-ol: rgba($color-ol,.9);

$w-border: 1px $opa-w1 solid;
