// トップページ

// 共通コンテンツ
.mainImg {
  //width: auto;
}
#cover {
  //width: 100%;
  //height: 300px;
  border-top: 1px $color-ol solid;
  img {
    width: 50%;
    //height: 300px;
  }
}
.slick-dotted.slick-slider {
  margin-bottom: 0;
}
.first article {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  a {
    display: block;
    position: relative;
    margin-bottom: 10px;
    width: 48%;
    height: auto;
    color: white;
    border: 3px #ddd solid;
    &:before {
      content: "";
      display: block;
      padding-top: 100%;
    }
    &:hover {
      opacity: .7;
    }
  }
  .topBox {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    padding: 0 10px;
    background: url(../images/message.jpg) center center no-repeat;
    background-size: cover;
    &.tb2 {
      background: url(../images/company.jpg) center bottom no-repeat;
      background-size: cover;
    }
    &.tb3 {
      background: url(../images/philosophy.jpg) center center no-repeat;
      background-size: cover;
    }
    &.tb4 {
      background: url(../images/planning.jpg) center center no-repeat;
      background-size: cover;
    }
    &.tb5 {
      background: url(../images/presentation.jpg) center center no-repeat;
      background-size: cover;
    }
    &.tb6 {
      background: url(../images/ir-library.jpg) center center no-repeat;
      background-size: cover;
    }
    &.tb7 {
      background: url(../images/finance.jpg) center bottom no-repeat;
      background-size: cover;
    }
    &.tb8 {
      background: url(../images/ir-callendar.jpg) center center no-repeat;
      background-size: cover;
    }
  }
  h3 {
    position: relative;
    top: 50%;
    font-family: 'Noto Sans JP', sans-serif;
    font-size: $f18;
    font-weight: 500;
    line-height: 1.5;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    text-shadow: 0 0 5px rgba(0,0,0,0.8);
  }
}
h2 {
  float: left;
  margin: 0;
  font-family: 'Noto Sans JP', sans-serif;
  font-size: $f28;
  font-weight: 100;
  letter-spacing: 0.05em;
}
.more {
  float: right;
  a {
    display: block;
    width: 9em;
    font-size: $f13;
    color: white;
    text-align: center;
    line-height: 28px;
    background: #aaa;
    &::before {
      margin-right: 10px;
      font-family: 'Ionicons';
      content: '\f363';
    }
    &:hover {
      background: $color-ol;
    }
  }
}
.news {
  clear: both;
  margin-bottom: 40px;
  padding: 15px 0 0;
  a {
    color: #333;
    &:hover {
      color: $color-ol;
    }
  }
}
dl {
  padding: 15px 0;
  border-bottom: 1px #ddd solid;
  line-height: 1.4;
}
dt {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  color: #999;
  letter-spacing: 0.08em;
}
.info {
  clear: both;
  padding: 15px 0 0;
  li {
    padding: 15px 0;
    border-bottom: 1px #ddd solid;
    line-height: 1.4;
    a {
      color: #333;
      &:hover {
        color: $color-ol;
      }
    }
  }
}
.group {
  padding: 20px;
  background: #eee;
  h2 {
    float: none;
    font-size: $f16;
    font-weight: 300;
    text-align: center;
    letter-spacing: 0;
  }
  a {
    img {
      width: 100%;
      margin-top: 15px;
      box-shadow: 0 0 2px #ccc;
      &:hover {
        opacity: .7;
      }
    }
  }
}


/* Tablet */
@media screen and (min-width:768px) {

  #cover {
    border-top: 2px $color-ol solid;
  }
  .first article {
    a {
      width: 24%;
    }
  }
  .second article {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .infoTitle {
    width: 30%;
  }
  h2 {
    float: none;
    margin-bottom: 20px;
    font-size: $f35;
  }
  .more {
    float: none;
  }
  .news,
  .info {
    padding: 0 0 0 30px;
    width: 70%;
    border-left: 1px #ccc solid;
  }
  dl:last-child,
  .info li:last-child {
    border-bottom: none;
  }
  .group {
    padding: 30px 40px 40px;
    h2 {
      margin-bottom: 10px;
    }
    article {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    a {
      width: 49%;
    }
  }

}


/* PC */
@media screen and (min-width:1040px) {

  #cover {
    //margin: 0 auto;
    //width: 1040px;
  }
  .slick-slider .slick-track, .slick-slider .slick-list{
    background: #fff;
  }
  .slick-initialized .slick-slide{
    opacity: 0.5;
  }
  .slick-initialized .slick-slide.slick-active{
    opacity: 1;
  }
  .slick-prev {
    left: 100px;
    width: 40px;
    height: 40px;
    z-index: 100;
  }
  .slick-next {
    right: 100px;
    width: 40px;
    height: 40px;
    z-index: 100;
  }
  .slick-prev:before,
  .slick-next:before {
    color: #999;
    font-size: 40px;
  }
  .first article {
    a {
      width: 24%;
      border: 5px #ddd solid;
    }
    h3 {
      font-size: $f24;
      text-shadow: 0 0 8px rgba(0,0,0,0.8);
    }
  }
  section {
    article {
      margin: 0 auto;
      padding: 0 20px;
      width: 1040px;
    }
  }
  .group {
    padding: 30px 20px 40px;
    a {
      width: 24.5%;
    }
  }

}
