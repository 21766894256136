// ミックスイン集

// テキスト画像置換
@mixin textindent {
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
}

// 天地中央
@mixin hcenter {
  display: table-cell;
  vertical-align: middle;
}

// FlexBox
@mixin flexbox {
  display: -webkit-box;
  display: -ms-flexbox;
  -js-display: flex;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

// 見出しライン
@mixin hline {
  position: absolute;
  top: 40%;
  left: 0;
  content: '';
  width: 30px;
  height: 1px;
  background: $color-blue;
}

// 矢印
@mixin tri {
  background: url(../common/tri.png) right 6px bottom 6px no-repeat;
  background-size: 15px;
}
